var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _vm._m(0),
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        {
          staticClass:
            "container container--fullWidth grid grid__col-small-big",
        },
        [
          _vm._m(1),
          _c("div", { staticClass: "wrapper--filled wrapper--paddingLg" }, [
            _c("div", { staticClass: "formGroup" }, [
              _c("label", { staticClass: "formLabel" }, [
                _vm._v("Referral Link"),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "formInputWithCopyBtn formInput formInput--fullWidth",
                  attrs: { name: "email", type: "text" },
                },
                [
                  _c("p", [_vm._v(_vm._s(_vm.referrLink))]),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:copy",
                          value: _vm.referrLink,
                          expression: "referrLink",
                          arg: "copy",
                        },
                      ],
                      staticClass: "btn btn--primary btn--xs",
                    },
                    [_vm._v("Copy Link")]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "container container--fullWidth grid grid__col-small-big",
        },
        [
          _vm._m(2),
          _c(
            "div",
            { staticClass: "wrapper--filled wrapper--paddingLg" },
            [
              _vm.activatedReferrals.length === 0
                ? _c("div", { staticClass: "h-textCenter" }, [
                    _c("h4", [
                      _vm._v(
                        "There are currently no referred friends signed up"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "Share the link above with more people to start earning money"
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._l(_vm.activatedReferrals, function (ref) {
                return _c(
                  "div",
                  { key: ref.id, staticClass: "invitedFriends" },
                  [
                    _c("div", { staticClass: "flexbox" }, [
                      _c("p", { staticClass: "invitedFriends__name" }, [
                        _vm._v(_vm._s(ref.referred_producer.display_name)),
                      ]),
                    ]),
                    _c("div", { staticClass: "flexbox" }, [
                      _c("p", { staticClass: "invitedFriends__date" }, [
                        _c("span", [_vm._v("Elite Account")]),
                        _vm._v(
                          " " + _vm._s(_vm._f("moment")(ref.date, "MM/DD/YYYY"))
                        ),
                      ]),
                      _c("p", { staticClass: "invitedFriends__money" }, [
                        _vm._v("$" + _vm._s(ref.amount)),
                      ]),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "header header--borderBottom" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _c("div", { staticClass: "headerContainer__heading" }, [
          _c("h1", [_vm._v("Referral")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "h-margin-30" }, [
      _c("h2", [_vm._v("Refer Friends")]),
      _c("p", [
        _vm._v(
          "Have friends sign up to sell their beats on TheCharts using your referral link and earn money."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "h-margin-30" }, [
      _c("h2", [_vm._v("Invited Friends")]),
      _c("p", [
        _vm._v(
          "List of friends who have signed up for an Elite account using your referral link."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }